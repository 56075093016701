#transactions {
  .transaction__card {
    min-height: 600px;
  }

  .transaction__item {
    padding-bottom: 1.6rem;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f5;
    }
  }
}
