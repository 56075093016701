.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
  text-align: center;

  &__icon {
    font-size: 5rem;
  }
}
