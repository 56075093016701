.footer {
  padding-top: 4rem;

  &__bottom {
    padding: 1.2rem 0;
    background-color: color(primary);

    .MuiTypography-root,
    .MuiLink-root {
      @include font-size(xs);
    }

    & > * {
      color: color(white);
    }
  }

  &__contact {
    .MuiTypography-root {
      @include font-size(sm);
    }
  }

  &__list {
    li {
      margin-left: 0;
      padding: 8px 0;

      @include respond(sm) {
        margin-left: -16px;
        padding: 8px 16px;
      }
    }
  }

  &__mb {
    margin-bottom: 4rem;
  }

  &__nav {
    .MuiLink-root {
      @include font-size(xs);

      @include respond(sm) {
        @include font-size(sm);
      }
    }

    .MuiAccordion-root {
      &::before {
        background-color: color(primary);
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    transition: color 0.3s;
    border-radius: 50%;
    opacity: 0.7;
    background-color: color(secondary);
    color: color(white);

    &:hover {
      opacity: 1;
    }

    & > * {
      @include font-size(md);
    }
  }
}
