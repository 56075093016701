.MuiTypography-body1,
p,
ul,
ol,
li {
  @include font-weight(regular);
  @include font-size(rg);
  line-height: 1.6;
}

.MuiTypography-h1,
h1 {
  @include font-size(xl);

  @include respond(xs) {
    @include font-size(xe);
  }

  @include respond(sm) {
    @include font-size(ma);
  }
}

.MuiTypography-h5,
.MuiTypography-h4,
.MuiTypography-h6,
h4,
h5,
h6 {
  @include font-weight(bold);
}

.MuiTypography-h5,
h5 {
  @include font-weight(bold);
}

.MuiMenuItem-root {
  @include font-weight(regular);
  @include font-size(sm);
}

.MuiAlert-message {
  @include font-size(sm);
}
