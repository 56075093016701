@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700;800&display=swap');

// Soleil font family
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/soleil/Soleil-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/soleil/Soleil-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/soleil/Soleil-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/soleil/Soleil-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/soleil/Soleil-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/soleil/Soleil-Extrabold.ttf') format('truetype');
}

// Circular font family
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/circular/CircularStd-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/circular/CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/circular/CircularStd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/circular/CircularStd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/circular/CircularStd-Black.otf') format('opentype');
}

// icomoon font kit
@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/icomoon/icomoon.eot');
  src: url('../../assets/fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../../assets/fonts/icomoon/icomoon.woff') format('woff'),
    url('../../assets/fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-display: block;
}
