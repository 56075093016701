.dashboard {
  &__header {
    margin-left: 1.4rem;

    @include respond(md) {
      margin-left: 2.4rem;
    }

    h4 {
      @include font-size(md);
      @include font-weight(bold);
      margin-top: 1rem;

      @include respond(md) {
        @include font-size(lg);
      }
    }

    p {
      @include font-size(sm);

      @include respond(md) {
        @include font-size(rg);
      }
    }
  }

  .customer-badge {
    @include font-size(xs);
    @include font-weight(medium);
    display: flex;
    flex: 0 35px;
    align-items: center;
    justify-content: center;
    width: 35;
    height: 35px;
    margin: 0;
    border-radius: 50%;
    background-color: color(blue2);
    color: color(white);
  }
}
