@mixin font-family($keyword) {
  font-family: get-font-family($keyword);
}

@mixin font-size($keyword) {
  font-size: get-font-size($keyword);
}

@mixin font-weight($keyword) {
  font-weight: get-font-weight($keyword);
}

@mixin respond($keyword) {
  // default will be small mobile phones

  @if get-break-point($keyword) {
    @media only screen and (min-width: get-break-point($keyword)) {
      @content;
    }
  }
}

@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  -o-transition-duration: $time;
  transition-duration: $time;
}

@mixin box-shadow($content) {
  -webkit-box-shadow: $content;
  box-shadow: $content;
}

@mixin page-card {
  border-radius: 16px;
  background-color: color(white);
  box-shadow: 0px 4px 5px rgba(163, 160, 234, 0.1);
}

@mixin bg-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(24, 59, 86, 0.0001) 0%,
    rgba(22, 49, 70, 0.453847) 45.38%,
    #152532 100%
  );
}

@mixin bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
