.card {
  padding: 0.5rem;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 5px rgba(163, 160, 234, 0.1));

  @include respond(md) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  &-content {
    width: 100%;
    padding: 1.5rem;
  }
}

.MuiCardContent-root:last-child {
  padding-bottom: 1.5rem;

  @include respond(md) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
