.product-card {
  display: flex;
  position: relative;
  align-items: flex-end;
  height: 180px;
  transition: all 0.4s ease;
  border-radius: 8px;

  @include respond(sm) {
    height: 250px;
  }

  @include respond(md) {
    height: 330px;
  }

  &:hover {
    filter: drop-shadow(0px 23px 25px rgba(20, 54, 80, 0.41));
  }

  .MuiCardContent-root {
    padding-bottom: 5px;

    @include respond(md) {
      padding-bottom: 24px;
    }
  }

  &__content {
    position: relative;
    z-index: 3;

    .button--white {
      @include font-size(sm);
      border-radius: 50px;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  .MuiChip-root {
    @include font-size(xx);
    height: auto;
    padding: 4px 0;
    border-color: #fafcff;
    color: color(white);

    @include respond(md) {
      padding: 6px 8px;
    }
  }

  .MuiTypography-root {
    @include font-size(md);
    @include font-weight(semi-bold);
    color: color(white);
    line-height: 40px;

    @include respond(xs) {
      @include font-size(lg);
      line-height: 48px;
    }

    @include respond(md) {
      @include font-size(ml);
    }
  }

  &--overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(24, 59, 86, 0.0001) 0%,
      rgba(22, 49, 70, 0.453847) 45.38%,
      #152532 100%
    );
  }
}
