[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

.icon-user-plus::before {
  content: '\e902';
}
.icon-check-circle::before {
  content: '\e900';
}
.icon-warning::before {
  content: '\e901';
}
.icon-refund::before {
  content: '\e905';
}
.icon-subscribe::before {
  content: '\e906';
}
.icon-overdue::before {
  content: '\e907';
}
.icon-mail::before {
  content: '\e908';
}
.icon-bar-chart::before {
  content: '\e909';
}
.icon-history::before {
  content: '\e90a';
}
.icon-menu::before {
  content: '\e90b';
}
.icon-wallet::before {
  content: '\e904';
  color: #ffc700;
}
.icon-discount::before {
  content: '\e903';
  color: #ffc700;
}
.icon-check::before {
  content: '\e90c';
}
