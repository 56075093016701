.header {
  &__logo {
    width: 65px;
    height: auto;

    @include respond(sm) {
      width: 90px;
    }

    &-link {
      font-size: inherit;
    }
  }

  &__nav {
    padding: 1.5rem 0;

    @include respond(sm) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  .nav {
    &__item {
      &:not(:last-child) {
        @include font-size(rg);
        padding: 0;
        color: color(primary);
      }

      &:last-child {
        margin-left: 3rem;
      }

      a.no-link {
        padding: 1.2rem 2.6rem;
        font-size: inherit;
      }
    }
  }
}
