.MuiChip-root {
  @include font-size(xx);
  height: auto;
  padding: 5px 10px;

  //   @include respond(md) {
  //     @include font-size(xs);
  //   }
}

.chip {
  &--customers {
    @include font-size(xs);
    @include font-weight(medium);
    border-radius: 8px;
  }

  &--doctor-dey {
    background-color: rgba($color: #ef5da8, $alpha: 0.2);
    color: #ef5da8;
  }

  &--ilara-eko {
    background-color: rgba($color: #5d5fef, $alpha: 0.2);
    color: #5d5fef;
  }
}
