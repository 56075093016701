.MuiButton-root {
  @include font-size(sm);
  @include font-weight(semi-bold);

  padding: 1.2rem 2.6rem;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
}

.MuiButton-containedSuccess {
  color: color(white);
}

.button {
  &--green {
    border-radius: 6px;
    background-color: color(green1);
    color: color(white);
    filter: drop-shadow(0px 4px 20px rgba(110, 142, 179, 0.42));

    &:hover {
      background-color: darken($color: color(green1), $amount: 20%);
      color: color(white);
    }
  }

  &--round {
    border-radius: 50px;
  }

  &--white {
    background-color: color(white);
    color: color(secondary);

    &:hover {
      color: color(white);
    }
  }

  &--lg {
    padding-right: 3rem;
    padding-left: 3rem;

    @include respond(md) {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}
