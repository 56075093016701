.badge {
  .MuiChip-root.MuiChip-filled {
    @include font-size(xs);
    @include font-weight(medium);
    padding: 5px 0;
    border-radius: 5px;
  }

  &--successful,
  &--completed {
    .MuiChip-root.MuiChip-filled {
      background-color: rgba($color: color(green2), $alpha: 0.15);
      color: color(green1);
    }
  }

  &--withdraw {
    .MuiChip-root.MuiChip-filled {
      background-color: rgba($color: color(blue1), $alpha: 0.15);
      color: color(blue1);
    }
  }

  &--declined {
    .MuiChip-root.MuiChip-filled {
      background-color: rgba($color: color(secondary), $alpha: 0.15);
      color: color(secondary);
    }
  }
}
