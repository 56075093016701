#topup {
  .topup {
    &__card {
      width: 100%;
    }

    &__info {
      margin-bottom: 3rem;
      padding: 2rem 1.5rem;
      border: 1px solid color(blue2);
      border-radius: 8px;
      background-color: rgba($color: #d8ecff, $alpha: 0.7);
    }
  }
}
