@each $name, $weight in $font-weights {
  .fw-#{'' + $name} {
    font-weight: $weight !important;
  }
}

@each $name, $size in $font-sizes {
  .fs-#{'' + $name} {
    font-size: $size !important;
  }
}

@each $name, $color in $colors {
  .bg-#{'' + $name} {
    background: $color !important;
  }

  .color-#{'' + $name} {
    color: $color !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.8rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-2 {
  margin-right: 0.75rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-2 {
  margin-left: 0.75rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.no-link {
  &:hover {
    text-decoration: none;
  }
}

.advert {
  width: 100%;
  padding: 3rem 0;
  text-align: center;

  img {
    height: 139px;

    @include respond(sm) {
      height: 150px;
    }
  }
}

.img-fluid {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
