.auth {
  &--bg {
    @include bg;
  }

  &--overlay {
    @include bg-overlay;
  }

  .login {
    &__header {
      display: flex;
      position: relative;
      align-items: flex-end;
      height: 200px;
      padding: 1rem 3.4rem 3rem;

      p {
        @include font-size(ml);
        @include font-weight(light);
        position: relative;
        z-index: 3;
        color: color(white);
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      padding: 4rem 0 2.5rem;

      @include respond(md) {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }

      h1 {
        @include font-size(ml);
        @include font-weight(bold);
        margin-bottom: 2rem;
      }
    }
  }

  &__link {
    color: color(blue1);
    @include font-size(sm);
  }

  &.welcome {
    position: relative;
    min-height: 70vh;

    .MuiTypography-h1 {
      @include font-size(ml);
      color: color(white);
      text-align: center;
    }
  }

  .welcome {
    &__card {
      width: 335px;
      max-width: 100%;
      margin: 2rem auto 0;
      padding: 4rem 2rem;
      text-align: center;
    }

    &__inner {
      position: relative;
      z-index: 3;
      padding-top: 9rem;
    }
  }
}
