.customers {
  width: 100%;

  &-form {
    @include page-card;
    margin-top: 2.1rem;
    padding: 3rem 1.6rem;

    @include respond(md) {
      padding: 6rem;
    }

    .MuiStepper-horizontal {
      margin-top: 2.5rem;
      margin-bottom: 3.8rem;
    }
  }
}
