.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40rem;
  max-width: 90%;
  min-height: 40rem;
  padding: 4rem;
  transform: translate(-50%, -50%);
  border-radius: 18px;
  background-color: color(white);

  &__confirmation {
    text-align: center;
  }

  a {
    text-decoration: underline;
  }
}
