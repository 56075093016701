#profile {
  .profile-form {
    @include page-card;
    padding: 3.5rem 2rem;
  }

  .profile {
    &__avatar {
      width: 80px;
      height: 80px;
      margin-right: 8px;
      background-color: color(primary);
    }

    &__button {
      @include font-size(xs);
      padding: 0.7rem 2.5rem;
      transition: background-color 0.3s;
      border: 0;
      border-radius: 4px;
      background: #95ccff;
      color: color(white);

      &-wrapper {
        position: relative;
        width: auto;
        height: auto;

        &:hover {
          .profile__button {
            background: darken($color: #95ccff, $amount: 20%);
          }
        }

        input {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}
