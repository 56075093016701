.page {
  &-inner {
    position: relative;
    min-height: 70vh;
    padding-bottom: 3rem;
  }

  &-wrapper {
    min-height: 70vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: color(ash1);
  }

  &--no-footer {
    min-height: calc(100vh - 145px);
    padding-bottom: 0;

    .page-wrapper {
      min-height: calc(100vh - 145px);
    }
  }
}
