.MuiFormControl-root {
  width: 100%;
}

.MuiFilledInput-root {
  @include font-size(rg);
  border-radius: 8px;
  background: color(ash1);
}

.MuiFormLabel-root {
  @include font-size(sm);
}

.MuiFormControlLabel-label {
  @include font-size(sm);
}

.MuiDatePickerToolbar-root {
  .MuiDatePickerToolbar-title {
    font-size: 3.4rem;
  }

  .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 2.4rem;
  }

  .MuiTypography-overline {
    font-size: 1.2rem;
  }
}

.MuiCalendarPicker-root {
  .PrivatePickersFadeTransitionGroup-root {
    font-size: 1.6rem;
  }

  .MuiPickersArrowSwitcher-root {
    font-size: 1.8rem;
  }

  .MuiSvgIcon-fontSizeMedium {
    font-size: 2.4rem;
  }

  .MuiPickersDay-root,
  .MuiTypography-caption {
    font-size: 1.2rem;
  }
}
