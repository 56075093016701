.sidebar {
  &__logo {
    @include font-size(md);
  }

  &__list {
    .MuiTypography-body1 {
      @include font-size(sm);
      @include font-weight(medium);
    }
  }
}
