#product {
  .product {
    &__inner {
      margin-bottom: 4rem;

      h5 {
        margin-bottom: 1rem;
      }
    }

    &__body {
      @include page-card;
      padding: 3.5rem 2rem;
      box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.08);
    }

    &__header {
      position: relative;
      width: 100%;
      height: 200px;
      margin-bottom: 1.4rem;
      padding: 1rem 3.4rem 3rem;

      &--bg {
        @include bg;
      }

      &--overlay {
        @include bg-overlay;
      }
    }

    &__content {
      color: #4a4a4a;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.6;

      h1 {
        font-size: 28px;
      }

      img {
        margin: 20px 0;
      }

      h2 {
        margin-bottom: 1.5rem;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.3;
        text-align: left;
      }

      h3 {
        position: relative;
        margin-bottom: 1.5rem;
        padding-left: 30px;
        color: #000;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
      }

      h3::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 10px;
        height: 20px;
        background: #ed4a4f;
      }

      h5 {
        position: relative;
        padding-left: 30px;
        font-size: 40px;

        &::after {
          content: '';
          position: absolute;
          top: 20px;
          left: 0;
          width: 10px;
          height: 20px;
          background: #ed4a4f;
        }
      }

      ul {
        margin: 1rem 0 5rem;
        margin-bottom: 5rem;
        list-style: none;
        counter-reset: my-counter;
      }

      li {
        position: relative;
        margin-bottom: 1rem;
        padding-left: 40px;
        font-size: 16px;
        line-height: 25px;

        &::before {
          content: '\e90c';
          display: flex;
          position: absolute;
          top: 3px;
          left: 0;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(54, 179, 126, 0.15);
          color: #36b37e;
          font-family: 'icomoon';
          font-weight: 700;
        }
      }

      .paragraph {
        margin-bottom: 1.5rem;
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
      }

      .paragraph--big {
        font-size: 18px;
        line-height: 2;
        line-height: 1.8;
      }
    }
  }
}
