#subscriptions {
  .subscriptions-wrapper {
    @include page-card;
    min-height: 50rem;
    padding: 2.5rem 2rem;
  }

  .subscription__item {
    padding: 2rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f5;
    }
  }
}
