.MuiTabScrollButton-root {
  svg {
    @include font-size(md);
  }
}
.MuiTabs-root {
  .MuiTab-root {
    @include font-size(xs);
    min-height: auto;
    padding-right: 2rem;
    padding-left: 2rem;
    border: 0;
    border-radius: 30px;
    color: color(primary);
    text-transform: capitalize;

    @include respond(md) {
      @include font-size(rg);
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }

  .Mui-selected {
    @include font-weight(bold);
    background-color: color(primary);
    color: color(white);
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled {
    display: none;
  }
}
